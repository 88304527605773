<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            title="水费交纳"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <div class="section-box">
      <div class="main-top" style="background:#fff">
        <div class="main-top-div1">户号：{{bindInfo.accountNo}}</div>
        <div class="main-top-div2">户名：{{bindInfo.accountName}}</div>
        <div class="main-top-div3">地址：{{bindInfo.address}}</div>
        <div style="position:absolute;height:40px;width:120px;top:52px;right:10px;">
            <van-dropdown-menu>
              <van-dropdown-item :value="value1" :options="option1" title="切换户号" @change='handelAccountChange' popup-style="color:red;background:red"/>
            </van-dropdown-menu>
        </div>
      </div>
    </div>
    <van-empty v-if="!billList.length>0&&!isShow" description="当前户号不存在欠费信息" class="collapse-content" />
    <table class="bill-base-detail" v-if="billList.length>0">
      <tr>
        <th style="width:60px;">选择</th>
        <th style="text-align:left;">账单月份</th>
        <th style="text-align:right;width:140px;">金额（元）</th>
        <th style="width:80px;"></th>
      </tr>
      <tr v-for="(bill,index) in billList">
        <td><input type="checkbox" name="bill-item" :value="bill.账单编号+'|'+bill.合计金额+'|全部|'+bill.账单月份" v-model="selBillList"></td>
        <td style="text-align:left;">{{bill.账单月份}}</td>
        <td style="text-align:right">{{bill.合计金额}}</td>
        <td><a @click="toDetail(bill.账单编号)">详情</a></td>
      </tr>
      <tr>
        <td>
          <input type="checkbox" :checked="defaultChecked" @click="onSelectAllBill">
        </td>
        <td style="text-align:left;font-weight:600;">全选</td>
        <td colspan="2" style="text-align:right;font-weight:600;"><span style="padding-right:10px">合计（元）:</span>{{selBillTotalAmount}}</td>
      </tr>
      <tr>
        <td colspan="4" style="text-align:right;font-weight:800;border-bottom:0px;">
          <van-button  type="info" hairline size="small" @click="toSettlement"  is-link>去结算</van-button>
        </td>
      </tr>
    </table>
    <ul class="message-ul">
	  	<li class="title">温馨提示：</li>
	  	<li>1、每天交费时间为：2:00-23:50；</li>
	  	<li>2、1-11月每月倒数第二天和12月31日为盘点日，暂停交费；</li>
	  	<li>3、银行托收用户不能使用微信交费。</li>
	  </ul>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getKey} from "../../utils/cookie";
import {getInfo, getBillList} from "../../api/billSearch";
export default {
  name: "index",
  components:{Loading},
  data(){
    return{
      defaultChecked:false,
      billList:[],
      selBillList:[],
      selBillNoSet:'',
      isShow : true,
      lodingTxt: '加载中...',
      accountNo: '',
      isLogin: false,
      bindInfo:{},
      indexA:false,
      indexB:false,
      result: ['a', 'b'],
      option1: [],
      value1: 0,
    }
  },
  computed: {
        selBillTotalAmount: function () {
            let totalAmount=0.00;
            let billNoList=[];
            this.selBillList.forEach(selBill => {
              billNoList.push(selBill.split("|")[0]);
              let amountStr=selBill.split("|")[1];
              let billAmount=parseFloat(amountStr);
              totalAmount=totalAmount+billAmount;
            });
            this.selBillNoSet=billNoList.join(",");
            return totalAmount;
        }
  },
  created() {
    //重新获取登录状态
    const isLogin = (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false
    this.isLogin = isLogin
    console.log('islogin='+isLogin)
    if (isLogin){
      const userinfo = JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}')

      if (userinfo && userinfo.account){
        if(localStorage.getItem("selectedAccount")!=null){
          let selectedAccountTemp=JSON.parse(localStorage.getItem("selectedAccount"));
          //this.bindInfo=JSON.parse(localStorage.getItem("selectedAccount"));
          let i = 0
          userinfo.waterUserList.forEach(item => {
            let account={}
            account.text=item.accountNo+"【"+item.address+"】";
            account.value=item.accountNo;
            account.bindInfo=item;
            this.option1.push(account);
            if(selectedAccountTemp.accountNo==item.accountNo){
                this.bindInfo=item;
            }
            i ++;
          });
          console.log("--------bindINfo1="+this.bindInfo+"||||||"+JSON.stringify(this.bindInfo));
          if(this.bindInfo){
            const bindIndex = parseInt(getKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX) || '0')
            this.bindInfo=userinfo.waterUserList[bindIndex]
          }
          console.log("--------bindINfo2="+this.bindInfo+"||||||"+JSON.stringify(this.bindInfo));
        }else{
          //获取选中的绑定的户号
          const bindIndex = parseInt(getKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX) || '0')
          console.log('bindIndex='+bindIndex)
          let i = 0
          userinfo.waterUserList.forEach(item => {
            if (i == bindIndex){
              console.log(item)
              this.bindInfo = item
            }
            let account={}
            account.text=item.accountNo+"【"+item.address+"】";
            account.value=item.accountNo;
            account.bindInfo=item;
            this.option1.push(account);
            i ++;
          });
        }
        localStorage.setItem("selectedAccount",JSON.stringify(this.bindInfo));
        this.queryBillList();
      }
    }
  },
  methods:{
    toSettlement:function(){
      const that=this;
      if (that.selBillList.length==0){
        this.$toast('请选择需要交费的账单')
        return
      }
      let queryPar={}
      queryPar.billNoSet=that.selBillList;
      this.$router.push({path:'../payment/settlement',query:queryPar})
    },
    toDetail:function(billNo){
      //console.log("账单编号："+JSON.stringify(billNo));
      const that=this;
      if (billNo==""){
        this.$toast('请选择需要查看的账单')
        return
      }
      let queryPar={};
      queryPar.accountNo=that.bindInfo.accountNo;
      queryPar.address=that.bindInfo.address;
      queryPar.accountName=that.bindInfo.accountName;
      queryPar.billNo=billNo;
      this.$router.push({ path:'../payment/billDetail',query:queryPar})
    },
    onBack : function (){
      this.$router.go(-1);
    },
    onChange(event){
      console.log(JSON.stringify(event));
      this.indexA=true;
      this.indexB=true;
    },
    onBindAccount(event){
        console.log("全选操作:onBindAccount"+JSON.stringify(event));

    },
    handelAccountChange(value){
       //console.log(value+"----"+JSON.stringify(this.option1.filter(item => item.value === value)))
       this.bindInfo=this.option1.filter(item => item.value === value)[0].bindInfo;
       localStorage.setItem("selectedAccount",JSON.stringify(this.bindInfo));
       this.queryBillList();
    },
    queryBillList(){
      const that=this;
      that.isShow=true;
      that.defaultChecked=false;
      const accountNo=that.bindInfo.accountNo;
      if(accountNo==""||accountNo=="undefined")return;
      getBillList(accountNo).then(response => {
        //console.log("账单查询结果:"+JSON.stringify(response))
        that.billList = response.data.billList;
        if(that.billList==null||that.billList.length==0){
          that.$toast('未查询到账单');
        }else{
         that.onSelectAllBill();
        }
        that.isShow=false;
      }).catch(() => {
        that.isShow=false;
        that.$toast('查询账单出错，请稍后再试');
      });
    },
    onSelectAllBill(){
        //console.log("全选操作:");
        this.selBillList=[];
        if(this.defaultChecked){
          this.defaultChecked=false;
        }else{
          this.defaultChecked=true;
          this.billList.forEach(bill => {
            let billStr=bill.账单编号+'|'+bill.合计金额+'|全部|'+bill.账单月份;
            this.selBillList.push(billStr);
          });
        }
    },
  }
}
</script>

<style scoped>
.main-top{
  padding: 0px;
  border-bottom:1px solid #ebebeb;
  color:#323233;
  font-size:100%;
}

.main-top-div1{
  height:40px;
  padding:5px 10px 5px 10px;
  color:#323233;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div2{
  height:40px;
  padding:0px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div3{
  padding:5px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:20px;
  font-weight:normal;
}

.van-checkbox{
  margin-top:6px;
  margin-bottom:0px;
}

.van-checkbox span{
    width:1000px!import;
}
.main-top /deep/ .van-dropdown-menu .van-dropdown-menu__bar{
  height:40px !important;
}

.label-class-1{
  width:100%!import;
}

.bill-base-detail {
  width: 100%;
  font-size: 100%;
  color: #515151;
  border:0px solid #fff;
}

.bill-base-detail th {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color:#ffffff;
  background: #1989fa;
}

.bill-base-detail td {
  padding:5px 10px 4px 5px;
  border-bottom:1px solid #ddd;
  height: 40px;
  line-height: 30px;
  text-align: center;
  background: #fff;
}

.bill-base-detail td input{
  height:18px;
  width:18px;
  background-color: #1989fa!important;
}
.bill-base-detail td input:checked{;background-color: #1989fa;}

.bill-base-detail td a{
  color:#1989fa;
}

.message-ul{
	margin:10px 2px 20px 2px;
	padding:10px;
	line-height:20px;
	background:#eee;
}
.message-ul li{
	font-size:14px;
}

.message-ul .title{
	font-weight:bold;
  line-height:30px;
}
</style>
</style>
